.tournament-card-score-section {
    display: flex;
    padding: 15px 14px 15px 10px;

    &__title {
        font-size: 10px;
        line-height: 14px;
    }

    &__score-subsection {
        padding: 0 14px 0 8px;
    }

    &__score-line {
        display: flex;
        align-items: center;
        padding-right: 11px;
    }

    &__server-wrapper {
        padding: 3px 0;
    }
}