@import './../../variables.scss';

.creative-tour-detail {
    textarea:focus,
    input:focus {
        outline:none!important;
        border-color: #2684FF!important;
        box-shadow: 0 0 0 1px #2684ff!important;
    }

    .holder-select input{
        border-color: transparent!important;
        box-shadow: none!important;
    }

    &__gray-input {
        input {
            background-color: #F2F2F2;
        }   
    }

    &__gray-item {
        background-color: #F2F2F2;
    }

    .was-validated .form-control:invalid, .form-control.is-invalid,
    .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{
        border-color: #dc3545!important;
        box-shadow: 0 0 0 1px #dc3545!important;
    }

    .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        border-radius: 16px;
    }
     
    .btn-outline-dark:hover{
        path{
          fill: white;
        }
      }
      
      .accordion{
        img{
          object-fit: cover;
        }
        .accordion-button:hover{
          opacity: .8;
          cursor: pointer;
        }
        .card{
          .accordion-button{
            box-shadow: none;
            width: auto;
          }
        }
        .card:first-of-type .card-header{
          border-radius: calc(1.25rem - 1px);
        }
        .card:first-of-type,
        .accordion-item:first-of-type {
          border-top-left-radius: 1.25rem;
          border-top-right-radius: 1.25rem;
        }
        .card:last-of-type,
        .accordion-item:last-of-type {
          border-bottom-right-radius: 1.25rem;
          border-bottom-left-radius: 1.25rem;
        }
        .accordion-button{
          padding-top: .5rem;
          padding-bottom: .5rem;
          &:not(.collapsed) {
            background-color: #FFFFFF;
            &:after{
              transform: rotate(0deg);
            }
          }
          &:after{
            transform: rotate(-90deg);
          }
          &.collapsed{
            .form-control{
              border-color: transparent;
            }
          }
        }
      }
      
      
      .chevronLeft{
        &:hover{
          path{
            fill: $active;
          }
        }
      }

}
