.information-tag {
    textarea:focus,
    input:focus {
        outline: none !important;
        border-color: #2684ff !important;
        box-shadow: 0 0 0 1px #2684ff !important;
    }

    .mapContainer {
        border-radius: 0.5rem;
        height: 212px;
        width: 212px;
        @media (max-width: 800px) {
            height: 353px;
            width: 353px;
        }
    }

    .dateInputContainer {
        width: 33.3%;
        @media (max-width: 991px) {
            padding-right: 0;
            width: 50%;
        }
        > div {
            max-width: 180px;
            @media (max-width: 991px) {
                max-width: 100%;
            }
        }
    }
}

.dashed-border {
    border: dashed 3px #5d6465 !important;
    &:hover {
        background-color: #e0dfdf;
    }
}

.helperText {
    font-size: 12px;
    margin-bottom: 0.2rem;
    text-align: center;
}
