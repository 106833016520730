.tournament-scorecard-page-match-modal {
    &__header {
        border-bottom: none;
        padding-bottom: 4px;
    }

    &__head {
        display: flex;
        justify-content: space-between;
    }

    &__tournament-icon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__tournament-class {
        text-align: right;
    }

    &__match-info {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }

    &__court {
        width: 100px;
    }

    &__date {
        width: 70px;
    }

    &__time {
        width: 40px;
    }

    &__status {
        width: 100px;
    }

    &__title {
        font-weight: 300;
        font-size: 10px;
        line-height: 140%;
        text-transform: uppercase;
        color: #04331E;
    }
    
    &__name {
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.02em;
    }
}