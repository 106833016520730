.btnDeleteConfigItem{
  height: 100%;
  padding: 0.35rem 0.45em;
  border-radius: 0rem 15px 15px 0;
}

.tour-modal__color-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .form-control-color {
    &:focus {
      box-shadow: none;
    }
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
}



