.standings-tournamet-list {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 30px;

    &__select-holder {
        width: 100%;
        overflow-x: auto;
        padding: 0 12px;
    }

    &__info-card {
        padding: 20px 15px 15px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}