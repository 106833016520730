.tournament-print-card-sign-section {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 13px 10px;

    &__item {
        margin: 0 7.5px;
        max-width: 150px;

        &:first-child {
            padding-left: 0;
        }
    
        &:last-child {
            padding-right: 0;
        }
    }

    &__info-block {
        font-size: 10px;
        line-height: 14px;
        height: 80px;

        &-side {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
        }

        &-timings {
            padding: 0 3px 0 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }

    &__sign-team {
        width: 100%;
        font-size: 10px;
        line-height: 14px; 

        &-box {
            height: calc(100% - 14px);
        }
    }

    &__sign {
        width: 100%;
        font-size: 10px;
        line-height: 14px; 

    }

    &__info-block-row {
        display: flex;

        &_single {
            display: flex;
            justify-content: center;
            align-items: center;
        }


        &_border-top {
            border-top: 1px solid #C5C9C9;
        }

        &_border-bottom {
            border-bottom: 1px solid #C5C9C9;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .tournament-print-card-sign-section__info-block-half-item {
            width: 50%;
        }

        .tournament-print-card-sign-section__info-block-text-item {
            width: 70%;
            justify-content: flex-start;
        }

        .tournament-print-card-sign-section__info-block-min-item {
            width: 30%;
            justify-content: flex-start;
        }
    }

    &__helper {
        height: 100%;

        .tournament-print-card-info-box__content {
            height: calc(100% - 14px);
        }
    }
}