.match-tournament-item {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 7px 15px rgb(0 0 0 / 25%);
    overflow: hidden;
    max-width: calc(100vw - 20px);
    min-width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
    &__avatar {
        &_second {
            margin-left: -3px;
        }
        &_third {
            z-index: 2;
            margin-top: -3px;
        }
        &_fourth {
            margin-left: -3px;
            z-index: 3;
            margin-top: -3px;
        }
    }
    &__teams {
        padding: 5px 10px;
        height: 100%;
    }

    &__team-holder {
        display: flex;
    }

    &__avatars-wrapper {
        display: flex;
        padding-right: 10px;
        
        &_two-players {
            min-width: 47px;
        }
    }

    &__team-name {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

    }

    &__footer {
        display: flex;
        background: linear-gradient(90deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 100%);
        padding: 3px 10px;
        align-items: center;

        &-number {
            min-width: 12px;
            padding-right: 8px;
        }

        &-time {
            min-width: 25px;
            padding-right: 4px;
        }

        &-header {
            font-size: 6px;
            font-weight: 800;
            line-height: 8px;
            letter-spacing: 0em;
            text-transform: uppercase;
        }

        &-body {
            font-size: 10px;
            font-weight: 300;
            line-height: 14px;
            letter-spacing: 0em;
        }
    }
}