@import './../../../../../variables.scss';


textarea:focus,
input:focus {
  outline:none!important;
  border-color: #2684FF!important;
  box-shadow: 0 0 0 1px #2684ff!important;
}
.holder-select input{
  border-color: transparent!important;
  box-shadow: none!important;
}

.no-classes{
  margin-top: 15rem;
  @media (max-width: 1100px){
    margin-top: 5rem;
  }
}

.relative {
  position: relative;
}

.match__accordion-status {
  position: absolute;
  top: 23%;
  right: 70px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateInputContainer{
  width: 33.3%;
  @media (max-width: 991px) {
    padding-right: 0;
    width: 50%;
  }
  > div{
    max-width: 180px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
}

.iconContainer{
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.imgContainer{
  border-radius: 0.5rem;
  height: 353px;
  width: 353px;
}

.mapContainer{
  border-radius: 0.5rem;
  height: 212px;
  width: 212px;
  @media (max-width: 800px) {
    height: 353px;
    width: 353px;
  }
}

.courtInputContainer{
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.courtBtnContainer{
  padding-top: 1.9em;
}

.settingInputContainer{
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.smallCourtBtn{
  height: 30px;
  min-width: 113px;
}

.btn-outline-dark:hover{
  path{
    fill: white;
  }
}

.btnDeleteMember{
  height: 100%;
  padding: 0.35rem 0.45em;
  border-radius: 0 15px 15px 0;
}

.accordion{
  img{
    object-fit: cover;
  }
  .accordion-button:hover{
    opacity: .8;
    cursor: pointer;
  }
  .card{
    .accordion-button{
      box-shadow: none;
      width: auto;
    }
  }
  .card:first-of-type .card-header{
    border-radius: calc(1.25rem - 1px);
  }
  .card:first-of-type,
  .accordion-item:first-of-type {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }
  .card:last-of-type,
  .accordion-item:last-of-type {
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
  }
  .accordion-button{
    padding-top: .5rem;
    padding-bottom: .5rem;
    &:not(.collapsed) {
      background-color: #FFFFFF;
      &:after{
        transform: rotate(0deg);
      }
    }
    &:after{
      transform: rotate(-90deg);
    }
    &.collapsed{
      .form-control{
        border-color: transparent;
      }
    }
  }
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 16px;
}

.chevronLeft{
  &:hover{
    path{
      fill: $active;
    }
  }
}
.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{
  border-color: #dc3545!important;
  box-shadow: 0 0 0 1px #dc3545!important;
}

.match-section__match-input {
  width: 100%;
}

.team-section__dummy {
  height: 289px;
}