.tournament-scorecard {
    min-height: calc(100vh - 100px);
    padding: 15px 15px 0 15px;
    position: relative;

    &__nav {
        display: flex;
        padding-bottom: 20px;
        align-items: center;
    }

    &__title {
        min-width: 200px;
    }

    &__back-button {
        display: flex;
        justify-content: center;
        align-items: center;

        &-holder {
            padding: 0 20px 0 13px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;

        position: relative;
    }


    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: 10;
        background: #fff9;
    }

    &__error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: absolute;
        top: 40px;
        bottom: 100px;
        left: 0;
        right: 0;

        cursor: pointer;
    }
}