@import '../../variables';

.organisation-settings {
    width: 640px;
    max-width: 100%;
    margin: auto;
    padding: 60px 15px 130px;

    .card, .card-header, .accordion-button {
        background: transparent !important;
        border: none !important;
    }

    .d-flex.justify-content-between.align-items-center.py-2.px-2 {
        flex-direction: row-reverse;
        justify-content: start !important;
        padding-left: 0 !important;
    }

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        text-align: center;
        margin-bottom: 50px;
    }

    &__image-button-section {
        text-align: center;
    }

    &__image-button-wrapper {
        margin-top: 25px;
    }

    &__image-text-wrapper {
        font-weight: 400;
        font-size: 16px;
        cursor: default;
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
    }

    &__submit-form-button svg {
        filter: invert(1);
    }

    &__single-item {
        display: flex;
        align-items: flex-start;

        &_text-field {
            input {
                width: 360px;
                max-width: 100%;
            }
        }

        &_submit {
            text-align: center;
            padding-left: 30px;
            margin-top: 24px;

            &-go {
                margin-top: 24px;
                padding-left: 30px;

                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &__owner-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        min-height: 50px;
        width: 100%;
        background: $greyBackground;
        border-radius: 16px;

        .raqtButtonLeftIcon {
            min-height: 50px;
            height: 100%;
            border: none;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            background-color: transparent;

            &:hover {
                background-color: #000; 
            }    
        }
    }

    &__image-text-wrapper {
        display: none;
    }

    &__add-admin-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 15px;
        height: 50px;
        background: $raqtBlack200;
        border-radius: 16px;

    }

    &__row {
        margin: 3px 0;
    }

    &__users-block {
        margin-top: 30px;
    }

    &__community-image-block {
        display: flex;
        justify-content: space-between;
    }

    .raqt-upload__image-wrapper {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start !important;

        [role=button] {
            width: 100%;
        }

        img {
            border-radius: 10px;
        }
    }

    .accordion .accordion-button {
        padding-left: 0;
    }

    .icon {
        filter: invert(1);
    }

    .imgContainer {
        height: 300px;
        width: 300px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .valid {
        input {
            background-image: url('../../assets/icons/svg/GreenCheck.svg');
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }
}

@media (max-width: 800px) {
    .organisation-settings {
        padding: 50px 15px 80px;

        &__title {
            font-weight: 300;
            font-size: 32px;
            line-height: 140%;
            text-align: center;
            margin-bottom: 50px;
        }

        &__single-item {
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            &_text-field {
                input {
                    width: 100%;
                }
            }

            &_submit {
                text-align: center;
                padding-left: 0;
                margin-top: 40px;

                &-go {
                    margin-top: 24px;
                    padding-left: 0;
                    text-align: center;
                }
            }
        }

        .imgContainer {
            max-height: 100%;
            height: 360px;
            max-width: 100%;
            width: 360px;
        }
   
        &__image-button-section {
            margin-left: 0;
            text-align: center;
        }

        &__button-wrapper {
            flex-direction: column-reverse;
        }

        &__image-text-wrapper {
            display: block;

            &_outside {
                display: none;
            }
        }

        .raqt-upload__image-wrapper {
            flex-direction: column !important;
            width: 100%;
            justify-content: space-between;
            align-items: center !important;
    
            img {
                border-radius: 10px;
            }
        }
    }
}