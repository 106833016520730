.tournament-match-list-table {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    overflow: auto;

    &__wrapper {
        width: 1362px;
    } 

    &__head-item {
        text-transform: uppercase;
    }

    &_centered {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__body {
        .item-wrapper {
            background: #fff;
        }
    }

    &__row-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
    }

    &__class {
        width: 20%;
        padding: 0 4px;
    }

    &__match-id {
        width: 10%;
        padding: 0 4px;
    }

    &__court {
        width: 10%;
        padding: 0 4px;
    }

    &__time {
        width: 10%; 
        padding: 0 4px;
    }

    &__team {
        width: 28%;
        padding: 0 4px;
    }

    &__result {
        width: 10%;
        padding: 0 4px;
    }

    &__status {
        width: 10%;
        padding: 0 4px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}