.no-classes{
    margin-top: 15rem;
    @media (max-width: 1100px){
      margin-top: 5rem;
    }
  }
  
.disabled-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.3);
}