.match-information-item-wrapper-gray {
    display: flex;
    align-items: center;
    min-height: 43px;
    width: 100%;
    border-radius: 16px;

    &_padding {
        padding: 0px 15px;
    }

    &_overflow-hidden {
        overflow: hidden;
    }
}