@import '../../variables';

.creative-dashboard {
    max-width: 1290px;
    width: 100%;
    margin: auto;
    margin-top: 20px;

    &__actionButtons {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 20px;
    }

    &__community-name {
        font-weight: 300;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $raqtPrimaryBlack;
        text-align: center;
        padding: 0px 0 10px;
    }

    &__community-settings {
        text-align: center;
        padding-bottom: 10px;

        &-button {
            &.raqtButton {
                background: $whiteBackground;
                border-color: $raqtBlack200Opacity;
                color: $raqtPrimaryBlack;
            }
            &.raqtButton:hover {
                background: $raqtBlack200Opacity;
            }
        }
    }

    &__section-title {
        text-align: left;
        font-weight: 300;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -0.02em;
        padding: 20px 30px 10px;
    }

    &__create-instances {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-bottom: 30px;
    }

    &__tournaments-holder {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 200px));
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 100%;
        text-align: left;
        padding-bottom: 100px;
    }

    &__tournaments-card {
        padding: 10px 10px 10px 10px;

        &-buttons-wrapper {
            display: flex;
        }

        &-button-holder {
            width: 33.3%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h5 {
            padding-top: 10px;
            min-height: 34px;
        }
    }

    &__tournament-img {
        width: 200px;
        height: 200px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    &__date {
        font-size: 12px;
    }
    &__tournamentName {
        font-size: 14px;
        font-weight: 700;
    }

    &__card {
        display: flex;
        justify-content: space-between;
        max-width: 200px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 12px rgb(0 0 0 / 19%);
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;
        background-color: $whiteBackground;
        margin: 10px;

        &-disabled {
            filter: opacity(0.35);
        }

        &-first {
            width: 80%;

            p {
                margin-bottom: 0;
            }
        }

        &-second {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            &_to-bottom {
                display: flex;
                align-items: flex-end;
            }
        }
    }

    &__stats {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .scaletext-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-quantity {
            width: 220px;
            gap: 20px;
            height: 35px;
            max-width: 100%;
            padding: 10px;
            text-align: center;
            font-weight: 700;
        }

        &-value-name {
            width: 100%;
            font-weight: 300;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            padding-bottom: 5px;
        }

        &-item {
            background: transparent;
            border: 1px solid $raqtBlack200;
            border-radius: 10px;
            margin: 5px;
        }
    }
}

@media (max-width: 800px) {
    .creative-dashboard {
        &__community-name {
            font-size: 32px;
        }

        &__community-settings {
            padding-bottom: 20px;
        }

        &__section-title {
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
            font-weight: 400;
            font-size: 22px;
            line-height: 140%;
            letter-spacing: -0.02em;
            margin: 0;
        }

        &__tournament-img {
            width: 280px;
            height: 280px;
            border-radius: 10px;
            margin-bottom: 10px;
        }

        &__tournaments-card {
            padding: 15px 15px 15px 15px;
            text-align: center;

            &-buttons-wrapper {
                display: flex;
            }

            &-button-holder {
                width: 33.3%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            h5 {
                padding-top: 10px;
                min-height: 34px;
            }
        }

        &__card {
            margin: 0;

            &-disabled {
                display: none;
            }
            &-wrapper {
                padding: 0 15px;
                flex-direction: column;
            }

            max-width: unset;
            width: 100%;
        }

        &__stats {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .scaletext-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-quantity {
                width: 70px;
                gap: 5px;
                height: 60px;
                min-width: 70px;
                max-width: 70px;
                padding: 10px;
                text-align: center;
                font-weight: 700;
            }

            &-value-name {
                width: 100%;
                font-weight: 300;
                font-size: 10px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                padding-bottom: 5px;
            }

            &-item {
                background: transparent;
                border: 1px solid $raqtBlack200;
                border-radius: 10px;
                margin: 5px;
            }
        }

        &__tournaments-holder {
            padding-bottom: 60px;
            grid-template-columns: repeat(auto-fill, minmax(398px, 398px));
        }

        &__tournaments-card {
            padding-top: 10px;
        }
    }
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle {
    margin-left: -10px;
    margin-top: 10px;
    color: #666;
    &:hover,
    &:focus {
        color: #333;
    }
}
