.tournament-scorecard-page-content {

    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: 10;
        background: #fff9;
    }
}