@import '../../variables.scss';


.raqt-organisation-list {

    padding-top: 40px;
    padding-bottom: 50px;
    margin-bottom:40px;
    height: 100vh; 




    /*
    margin: auto;
    max-width: 100%;
    width: 100%;
    
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    */


    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        text-align: center;
        margin-top: 65px;
        margin-bottom: 50px;
    }

    
    &__subtitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 60px;
    }

    &__card-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &__create-icon {
        margin-left: auto; /* Push the icon to the right */
        display:flex;
        align-items: center;
        margin-bottom:0px;
    }

    &__card_create {
        display: 'flex'; 
        align-items: center;
    }
    
    a {
        text-decoration: none;
        color: $raqtPrimaryBlack;
    }
    
    &__communities {
        
        margin: auto; /* Center horizontally */
        width: 80%;
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        justify-content: left; /* Center horizontally */
        align-items: flex-start; /* Align to the top vertically */
        flex-wrap: wrap; /* Allow cards to wrap onto next line */
        gap: 10px;
        
    }

    
    &__cardNew {
        display:flex;
        align-items: center;
        min-width: 392px;
        flex: 1 0 calc(25% - 20px); /* Set width for each card, considering the gap */  
        height: 70px; /* Set the height of each card */
        background-color: $whiteBackground;
        border-radius: 10px;  
        margin-bottom:10px; 
        width:100%;
        box-sizing: border-box;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;

    

    }

    &__cardContent {
        display:flex;
        align-items: center;
        flex-grow:1;
        gap:0px;
        width:auto;
        height:100%;
    

    }

    &__card {
        /* Your existing styles */
        height: 70px; /* Set the height of each card */
        width:auto;
        min-width: 392px;
        flex: 1 0 calc(25% - 20px); /* Set width for each card, considering the gap */
        max-width: calc(100% - 0px); 
        display: flex; /* Ensure flex layout */
        align-self: flex-start;
        align-items: center; /* Vertically center content */
        justify-content:flex-start;
        /*overflow: hidden; */
        box-sizing: border-box;
        background-color: $whiteBackground;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    }

    &__image {
        /* Your existing styles */
        flex: 0 0 auto; /* Ensure the image does not stretch */
        margin-right: 20px; /* Add spacing between the image and text */
        height: 50px;
        width: 50px;
    }


    &__filterList {

        display:flex;
        align-items: center;
        height: 70px; /* Set the height of each card */
        background-color: transparent;
        margin-bottom:10px; 
        width:100%;

    }

    &__filterInput {
        height: 70px;
        outline: none;
        padding: 1.0rem;
        background-color: $whiteBackground;
        width: 100%;
        border: 0.5px solid $textGray !important; /* Remove border */
        border-radius: 10px;
        font-size:18px;
    }


}


@media (max-width: 800px) {
    .raqt-organisation-list {


        padding-top: 15px;
        padding-bottom: 40px;
        height: 100vh;
        padding-left: 0px;
        padding-right: 0px;
       

        &__communities {
            width:100%;
            display: flex;
            flex-grow:1;
            flex-direction: row;
            align-self: flex-start;
            justify-content: left; /* Center horizontally */
            align-items: flex-start; /* Align to the top vertically */
            flex-wrap: wrap; /* Allow cards to wrap onto next line */
            gap: 0px;
            padding-left: 0px;
            padding-right: 0px;


        }

        &__cardNew {
            display:flex;
            align-items: center;
            flex-grow:1;
            height: 50px; /* Set the height of each card */
            background-color: $whiteBackground;
            border-radius: 0px;  
            margin-bottom:10px; 
            width:80%;

        }

        &__cardContent {
            display:flex;
            align-items: center;
            gap:0px;
            width:auto;
            height:100%;
      

        }

        &__card {
            display: flex;
            flex-basis: 100%; /* Take full width */
            
            height: 50px; /* Set the height of each card */
            box-sizing: border-box; /* Include padding and border in the width calculation */
            background-color: $whiteBackground;
            border-radius: 10px;
            padding: 10px 20px; /* Consolidated padding */
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);

        }

        &__image {
            /* Your existing styles */
            flex: 0 0 auto; /* Ensure the image does not stretch */
            margin-right: 20px; /* Add spacing between the image and text */
            height: 35px;
            width: 35px;
        }
       
        &__card-img-top {
            height: 35px;
            width: 35px;
        }

        &__create-icon {
            margin-left: auto; /* Push the icon to the right */
            display:flex;
            align-items: center;
            margin-bottom:0px;
        }

        &__card-title {
            font-weight: 700;
            font-size: 12px;

           
        }

        &__filterList {

            display:flex;
            align-items: center;
            height: 50px; /* Set the height of each card */
            background-color: transparent;
            margin-bottom:10px; 
            width:100%;

        }

        &__filterInput {
            width:100%;
            height: 50px;
            outline: none;
            padding: 0.5rem;
            background-color: $whiteBackground;
            border-top: 0.5px solid $textGray !important; /* Remove border */
            border-bottom: 0.5px solid $textGray !important; /* Remove border */
            border-radius: 0px;
    
        }

        
       
    }

    
    
}




