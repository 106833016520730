.bubble-with-text {
    border-radius: 100%;
    background: #F2F2F2;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 11px;
}