@import '../../variables';

.navContainer{
  min-height: 60px;
  background-color: $darkBackground;
  padding: 15px 15px 15px 15px;

  img.hidden {
    opacity: 0;
    transition: opacity .3s linear;
  }

  .hide {
    transform: scale(0);
  }

  img {
    opacity: 1;
    transition: opacity .3s linear;
  }

  .navbar-nav {
    text-align: center;

    a.nav-link {
      color: #FFFFFF;
    }
  }

  .nav{
    font-size: 16px;
  }
  

  a{
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-weight: 400;
    font-size: 24px;
  }

  .navbar-dark .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background:  transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat; // include transparent for button elements
  }

  .navbar-dark .navbar-toggler.collapsed .navbar-toggler-icon {
    background:  transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") center/1.5em auto no-repeat; // include transparent for button elements
  }
}

//
//background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
@media (max-width: 800px) {
  .navContainer{
    padding: 15px 15px 15px 15px;
  }

  .footer__container {
    display: block;
  }
}

.raqt-logo {
    user-drag: none;  
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}

.navbar{
  padding: 0;
}

.navbar-toggler{
  margin-right: 1em;
  margin-left: 1em;
  border: none;
}

.navbar-collapse {
  flex-grow: inherit;
}

.nav{
  font-weight: 600;
  color: #FFFFFF;
}

.avatarContainer{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}

.header-logo {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  cursor:default;
}

.navbar-brand {
  position: relative;
}

.mobile-logo {
  margin-left: 15px;
}