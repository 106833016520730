.courtInputContainer {
    margin: 0.5rem 0.5rem 0.5rem 0;
}

.courtBtnContainer {
    padding-top: 1.9em;
}
  
.smallCourtBtn {
    height: 30px;
    min-width: 113px;
}