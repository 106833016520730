.tournament-card-player-holder {
    padding-top: 15px;

    &__title {
        text-transform: uppercase;
    }

    &__player-info {
        display: flex;
    }

    &__player-name {
        margin-right: 5px;
    }

    &__odd-or-even {
        width: unset;
        text-transform: uppercase;
    }
}