.classes-tag {

    &__subclass-toggle {
        display: flex;
        align-items: center;
        margin-top: 30px;
        padding-left: 15px;

        p {
            margin: 0;
            padding-left: 10px;
        }
    }
}