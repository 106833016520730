.organisation-create {
    width: 780px;
    max-width: 100%;
    margin: auto;
    padding: 60px 15px 130px;

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        text-align: center;
        margin-bottom: 50px;
    }

    &__image-button-section {
        margin-left: 30px;
    }

    &__image-button-wrapper {
        margin-top: 25px;
    }

    &__image-text-wrapper {
        font-weight: 400;
        font-size: 16px;
        cursor: default;
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
    }

    &__submit-form-button svg {
        filter: invert(1);
    }

    &__single-item {
        display: flex;
        align-items: flex-start;

        &_text-field {
            input {
                width: 510px;
                max-width: 100%;
            }
        }

        &_submit {
            text-align: center;
            padding-left: 30px;
            margin-top: 24px;

            &-go {
                margin-top: 24px;
                padding-left: 30px;

                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .raqt-upload__image-wrapper {
        flex-direction: row !important;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start !important;

        img {
            border-radius: 10px;
        }
    }

    .icon {
        filter: invert(1);
    }

    .imgContainer {
        height: 300px;
        width: 300px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .valid {
        input {
            background-image: url('../../assets/icons/svg/GreenCheck.svg');
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }
}

@media (max-width: 800px) {
    .organisation-create {
        padding: 50px 15px 80px;

        &__title {
            font-weight: 300;
            font-size: 32px;
            line-height: 140%;
            text-align: center;
            margin-bottom: 50px;
        }

        &__single-item {
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            &_text-field {
                input {
                    width: 100%;
                }
            }

            &_submit {
                text-align: center;
                padding-left: 0;
                margin-top: 40px;

                &-go {
                    margin-top: 24px;
                    padding-left: 0;
                    text-align: center;
                }
            }
        }

        .imgContainer {
            max-height: 100%;
            height: 360px;
            max-width: 100%;
            width: 360px;
        }
   
        &__image-button-section {
            margin-left: 0;
            text-align: center;
        }

        &__button-wrapper {
            flex-direction: column-reverse;
        }

        .raqt-upload__image-wrapper {
            flex-direction: column !important;
            width: 100%;
            justify-content: space-between;
            align-items: center !important;
    
            img {
                border-radius: 10px;
            }
        }
    }
}