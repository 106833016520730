@import '../../variables';

.tournament-matches-stats {
    max-width: 1340px;
    margin: auto;
    width: 100%;
    padding: 57px 10px 130px;

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        letter-spacing: -0.02em;
        text-align: center;
        padding-bottom: 57px;
    }

    &__back-link {
        padding-bottom: 32px;

        a {
            text-decoration: none;
            display: flex;
            align-items: center;
        }
    }

    &__back-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
    }

    &__table-holder {
        overflow-x: auto;
        padding-top: 25px;
    }

    .raqt-table__body {
        .item-wrapper {
            background: $greyBackground;
        }
    }
}