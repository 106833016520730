
@import '../../variables';

.tournament-classes-stats {
    max-width: 1340px;
    margin: auto;
    width: 100%;
    padding: 57px 10px 130px;

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        letter-spacing: -0.02em;
        text-align: center;
        padding-bottom: 57px;
    }

    &__back-link {
        padding-bottom: 32px;
        
        a {
            text-decoration: none;
            display: flex;
            align-items: center;
        }
    }

    &__back-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
    }

    &__table-holder {
        overflow-x: auto;
        width: 650px;
        padding-top: 25px;
    }

    .raqt-table__body .raqt-table__column-falsy-value {
        .item-wrapper {
            background-color: transparent;
        }
    }

    .raqt-table__row-0 .raqt-table__column-truly-value {
        .item-wrapper {
            background-color: $raqtBlack200;
            font-weight: 600;
        }
    }

    .raqt-table__body {
        .raqt-table__column-2 {
            .item-wrapper {
                text-align: center;
                align-self: center;
                display: flex;
                justify-content: center;
            }
        }
    }

    .raqt-table__body {
        .item-wrapper {
            background: $greyBackground;
        }
    }
}