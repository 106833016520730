@import '../../variables';

.landing-page {
    min-height: calc(100vh - 80px);
    background-color: $darkBackground;

    &__logo svg{
        height: 191px;
        width: 348px;
        fill: #FAFAFA;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1400px;
        width: 100%;
        margin: auto;
    }

    &__subtitle {
        font-weight: 700;
        font-size: 48px;
        line-height: 140%;
        color: $textWhite;
        letter-spacing: -0.02em;
    }

    &__tournaments-holder {
        display: grid;
        grid-template-columns: repeat( auto-fill, minmax(320px,320px) );
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 100%;
        text-align: left;
        padding: 0 20px;
    }

    &__section-title {
        text-align: left;
        padding: 0 30px;
        font-weight: 800;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $textWhite;
    }

    &__tournaments-card {
        color: $textWhite;
        padding: 15px 10px;

        &-buttons-wrapper {
            display: flex;
        }

        &-button-holder {
            width: 33.3%;
            display: flex;
            justify-content: center;
            align-items: center;

            .raqtButton {
                min-width: unset;
                min-height: 30px;
                border: 0.3px solid #FAFAFA;
                background-color: transparent;
            }
        }

        h5 {
            padding-top: 10px;
            min-height: 34px;
        }
    }

    &__row {
        margin-top: 30px;
    }

    &__w-100 {
        width: 100%;
    }

    &__card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__card {
        display: flex;
        justify-content: space-between;
        width: 49%;
        max-width: 480px;
        border: 1px solid rgba(0,0,0,0.1);
        box-shadow: 0px 4px 12px rgb(0 0 0 / 19%);
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;
        background-color: $whiteBackground;

        img {
            height:35px;
        }

        &-first {
            width: 55%;
        }

        &-second {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            &_to-bottom {
                display: flex;
                align-items: flex-end;
            }

            p {
                margin-bottom: 0;
            }

            img {
                padding: 5px 0;
                height: 52px;
            }
        }
    }

    &__description {
        text-align: center;
    }

    &__association-logo {
        text-align: center;

        img {
            width: 50px;
            max-height: 50px;
        }
    }

    &__raqt-tv-holder {
        text-align: left;
        display: flex;
        overflow-x: auto;
        mask-image: linear-gradient(90deg, transparent, transparent 20px , #000 30px, #000 calc(100% - 30px),transparent calc(100% - 20px),transparent);
        white-space: nowrap;

        ::-webkit-scrollbar {
            background-color: transparent;
            width: 10px;
        }
    }

    &__raqt-tv-wrapper {
        display: flex;
        padding: 0 20px;
    }

    &__raqt-tv-img {
        width: 398px;
        height: 223px;
        border-radius: 10px;
    }

    &__tournament-img {
        width: 300px;
        height: 300px;
        border-radius: 10px;
    }

    &__entrepreneur {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-decoration-line: underline;
        color: #3797FF; 
    }

    &__date {
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        color: #F2F2F2;
    }
}

@media (max-width: 800px) {
    .landing-page {

        &__section-title {
            text-align: left;
            padding: 0 15px;
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: $textWhite;
            margin: 0;
        }

        &__raqt-tv-holder {
            text-align: left;
            display: unset;
            overflow-x: inherit;
            mask-image: none;
            white-space: inherit;
    
            ::-webkit-scrollbar {
                background-color: transparent;
                width: 10px;
            }
        }
    
        &__raqt-tv-wrapper {
            display: unset;
            padding: 0 20px;
        }

        &__raqt-tv-img {
            width: 100%;
            height: unset;
        }
        
        &__logo svg{
            height: 121px;
            width: 221px;
            fill: #FAFAFA;
        }

        &__subtitle{
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-align: center;
        }

        &__card {
            &-wrapper {
                padding: 0 15px;
                flex-direction: column;
            }

            margin: 20px 0;
            width: 100%;
        }

        &__tournament-img {
            width: 398px;
            height: 398px;
        }

        &__tournaments-card {
            padding: 15px 0;
        }

        &__tournaments-holder {
            grid-template-columns: repeat(auto-fill, minmax(398px, 398px));
            padding: 0;
        }
    }
}