@import '../../variables';

.plain-dropdown {
    width: 100%;
    position: relative;

    &__options {
        position: absolute;
        left: 0;
        top: 100%;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 7px 15px rgb(0 0 0 / 25%);
        padding: 10px;
        border: 1px solid $raqtBlack200;
        z-index: 1;
        width: max-content;
        min-width: 100%;
        max-height: calc(100vh - 150px);
        overflow: auto;
    }

    &__button-holder {
        display: flex;
        align-items: center;
        min-width: 200px;
        justify-content: space-between;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        position: relative;
    }

    &__option {
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        margin: 1px;

        &:hover {
            background: $raqtBlack200;
        }
    }
}