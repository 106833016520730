@import '../../../../../variables';

.tournament-stats {
    &__stats {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .scaletext-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-quantity {
            width: 300px;
            height: 200px;
            max-width: 100%;
            padding: 10px;
            text-align: center;
        }

        &-value-name {
            width: 100%;
            font-weight: 700;
            font-size: 32px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            padding-bottom: 30px;
        }

        &-item {
            background: #FFFFFF;
            border: 1px solid $raqtBlack200;
            border-radius: 10px;
            margin: 5px;
        }
    }

    &__section-title {
        text-align: left;
        font-weight: 800;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: -0.02em;
        padding: 50px 10px 30px;
    }
}

@media (max-width: 800px) {
    .tournament-stats {

        &__section-title {
            text-align: left;
            padding: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            letter-spacing: -0.02em;
            margin: 0;
        }
    }
}