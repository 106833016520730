@import '../../../../variables';

.bubble-tab-item {
    border: 1px solid #F2F2F2;
    padding: 5px 10px 5px 10px;
    border-radius: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    background: #FFFFFF;
    cursor: pointer;

    &_active {
        background: $raqtBlack200;
    }
}