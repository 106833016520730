.checks-password-form-group {
    &__single-item {
        display: flex;
        align-items: flex-start;

        &_text-field {
            input {
                width: 341px;
                max-width: 100%;
            }
        }

        &_submit {
            text-align: center;
            padding-left: 30px;
            margin-top: 40px;

            &-go {
                margin-top: 24px;
                padding-left: 30px;

                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &__double-item {
        display: flex;
        align-items: flex-start;

        input {
            width: 341px;
            max-width: 100%;
        }

        &_submit {
            text-align: center;
            padding-left: 30px;
            margin-top: 40px;
        }

    }
}

@media (max-width: 800px) {

    .checks-password-form-group {
        &__single-item {
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            &_text-field {
                input {
                    width: 100%;
                }
            }

            &_submit {
                text-align: center;
                padding-left: 0;
                margin-top: 40px;

                &-go {
                    margin-top: 24px;
                    padding-left: 0;
                    text-align: center;
                }
            }
        }

        &__double-item {
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
    
            input {
                width: 100%;
            }
    
            &_submit {
                text-align: center;
                padding-left: 0;
                margin-top: 40px;
            }
    
        }
    }
    
}