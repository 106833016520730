.raqtButton{
  min-width: 140px;
  min-height: 50px;
  border-radius: 2.25rem;
}

.raqtButtonLeftIcon{
  border-radius: 16px;
  border: 1px solid #ced4da;
  border-left: none;
  padding: 0px 0.5em;
  background-color: #FFFFFF;
}

.raqtButtonRightIcon{
  border-radius: 16px;
  border: 1px solid #ced4da;
  border-right: none;
  padding: 0px 0.5em;
  background-color: #FFFFFF;
}

.btnTabItem:not(.activeBtn):hover{
  transition: all .5s ease;
  box-shadow: 0 0 0 1px #C5C9C9;
  border-radius: 0.5rem;
}

.activeBtn{
  border-radius: 0.5rem;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
}

.tagContainer{
  min-height: 50px;
  border-radius: 0.55rem;
  background-color: rgba(118, 118, 128, 0.12);
  position: relative;
}

.raqt__radio-buttons {
  position: relative;

  &-disabled {
    position: absolute;
    width: calc(100% + 0.25rem * 2);
    height: calc(100% + 0.25rem * 2) ;
    top: -0.25rem;
    left: -0.25rem;
    z-index: 1;
    background-color: #ffffffa3;
    border-radius: 0.55rem;
  }
}