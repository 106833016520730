.bubble-tab-controller {
    display: flex;
    align-items: center;
    width: max-content;

    &__item-wrapper {
        margin: 0 2.5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}