.player-tournament-list {

    &__players-holder {
        display: flex;
        overflow: hidden;
        min-height: 83px;
    }

    &__players-sub-holder {
        display: flex;
        overflow-x: auto;
        padding: 2px 0;
    }

    &__player-wrapper {
        padding: 0 5px;
        width: min-content;
    }

    &__avatar-holder {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__name-holder {
        text-align: center;
        padding-bottom: 10px;
        width: 100%;
        min-width: 60px;
    }
}