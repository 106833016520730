.tournament-match-list {
    padding: 0 15px;
    min-height: calc(100vh - 200px);
    position: relative;

    &__loader {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        background-color: rgba(255,255,255, 0.4);
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__search-manager {
        padding: 7.5px 0;
    }

    &__page-name {
        font-weight: 700;
        font-size: 48px;
        line-height: 140%;
        color: #2C2F30;
    }

    &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #000;
    }

    &__filter-input {
        width: 400px;
        border: 1px solid #C5C9C9;
        max-width: calc(100vw - 30px);

        &-wrapper div {
            border: none;
        }
    }

    &__filter-list {
        display: flex;
    }

    &__filter {
        padding: 4px;

        &:first-child {
            padding-left: 0;
        }
    }

    &__pagination {
        width: 100%;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7.5px;
        max-width: 100vw;

        ul {
            user-select: none;
            display: flex;
            justify-content: center;
            list-style-type: none;
            width: 70%;
            max-width: 100vw;
            padding-left: 0;

            li {
                min-height: 43px;
                min-width: 43px;
                border-radius: 16px;
                border: solid 1px #F2F2F2;
                background: #fff;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 12px;

                &.disabled {
                    color: rgba(33, 37, 41, .3);
                }
                
                &.selected {
                    color: #fff;
                    background-color: #212529;
                    border-color: #212529;
                }

                &:first-child, &:last-child {
                    min-width: 85px;

                    a {
                        padding: 0;
                    }
                    
                }
            }

            a {
                color: inherit;
                text-decoration: none;
                font-weight: 600;
                line-height: 140%;
                display: flex;
                align-items: center;
                padding: 7px 14px;
            }
        }
    }
}

@media(max-width: 800px) {
    .tournament-match-list {
        min-height: calc(100vh - 100px);
    }
}