@import '../../../../../variables';

.imgContainer {
    border-radius: 0.5rem;
    height: 353px;
    width: 353px;
}

.iconContainer {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.featureContainer {
    border-radius: 0.5rem;
    height: 270px;
    width: 360px;
}

.previewBoxTop {
    position: absolute;
    top: 0;
    bottom: 90%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom: 2px dashed white;
}

.previewBoxBottom {
    position: absolute;
    top: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-top: 2px dashed white;
}

.previewCheckbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-radius: 16px;
    transition: all 0.3s ease;

    padding: 4px 12px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }
}

.previewCheckboxSelected {
    background-color: rgba(0, 0, 0, 0.08);
    border-color: rgba(0, 0, 0, 0.16);

    &:hover {
        background-color: rgba(0, 0, 0, 0.16);
        border-color: rgba(0, 0, 0, 0.24);
    }
}

.previewHelperText {
    font-size: 12px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
