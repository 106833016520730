.class-switch-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__form-input {
        width: 310px;
    }

    &__form-wrapper {
        min-width: 60%;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        label {
            font-style: italic;
        }
    }

    &__class-info {
        display: flex;
        width: 350px;
        justify-content: space-between;
        padding-left: 30px;
        height: 120px;
        align-items: center;
        flex-wrap: wrap;

        &-container {
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            padding: 30px 0;
        }

        &-name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h6 {
                font-weight: 600;
                margin: 0;
            }
        }

        &-information {
            font-style: italic;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (max-width: 560px) {
        .class-switch-form__class-info {
            padding-left: 17px;
        }
    }
}