.raqt-login {
    letter-spacing: -0.02em;


    &__main {
        margin: auto; /* Center horizontally */
        width: 50%;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        justify-content: left; /* Center horizontally */
        align-items:center; /* Align to the top vertically */
        margin-top:-700px;
        
    }
    
    &__header {
        font-weight: 300;
        font-size: 32px;
        line-height: 140%;
        margin-bottom: 40px;
    }

    &__mobile-error {
        display: none;
    }

    &__submit {
        text-align: center;
        padding-left: 30px;
        margin-top: 40px;

        svg {
            transform: rotate(90deg);
        }
    }

    &__password-block {
        display: flex;
    }

    &__links {
        display: flex;

        &_padding {
            padding: 0 30px;
        }
    }

    &__link {
        &_padding {
            // padding: 0 30px;
        }
    }
}

@media (min-width: 800px) {
    .raqt-login input {
        width: 400px;
    }
}


@media (max-width: 800px) {
    .raqt-login {
        min-height: calc(100vh - 460px);
    }

    .raqt-login__main {
        margin: auto; /* Center horizontally */
        width: 50%;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        justify-content: left; /* Center horizontally */
        align-items:center; /* Align to the top vertically */
        margin-top:-350px;
        
    }

    .raqt-login__header {
        font-weight: 300;
        font-size: 32px;
        line-height: 140%;
        text-align: center;
        margin-top: 65px;
        margin-bottom: 50px;
    }

    .raqt-login__password-block {
        display: block;
    }

    .raqt-login__submit {
        padding-left: 0;
        margin-top: 0;
         
        svg {
            display: none;
        }
    }

    .raqt-login__links {
        flex-direction: column;
        align-items: center;
    }

    .raqt-login__mobile-error {
        display: block;
    }

    .raqt-login__desktop-error {
        display: none;
    }

}