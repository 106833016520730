@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {
  outline:none!important;
  box-shadow: none!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vh-screen{
  height: 100vh;
}

.vh-min-screen{
  min-height: 100vh;
}

.bg-grey{
  background-color: #FAFAFA;
}

.mx-6{
  margin-left: 9em;
  margin-right: 9em;
  @media screen and (max-width: 1280px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.hide-svg {
  svg {
    opacity: 0;
  }
}

.fs-7{
  font-size: 0.7rem;
}

.br-16{
  border-radius: 16px;
}

.br-s-16{
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.min-h-1{
  min-height: 10rem;
}
.pt-lg-13{
  padding-top: 13rem;
  @media (max-width: 1100px) {
    padding-top: 6rem;
  }
}

.btnDeleteMember {
  height: 100%;
  padding: 0.35rem 0.45em;
  border-radius: 0 15px 15px 0;
}

.raqt__select-wrapper {
  width: 25%
}

.raqt__seed-holder {
  margin: 4px 8px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: #C5C9C9 1px solid;
}

@media (max-width: 1100px) {
  .raqt__select-wrapper {
      width: 100%
  }
}

.relative {
  position: relative;
}

.hide {
  opacity: 0;
  z-index: -100500;
}