@import '../../variables';

.tournament-tickets-stats {
    max-width: 1340px;
    margin: auto;
    width: 100%;
    padding: 57px 10px 130px;

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        letter-spacing: -0.02em;
        text-align: center;
        padding-bottom: 57px;
    }

    &__back-link {
        padding-bottom: 32px;
        
        a {
            text-decoration: none;
            display: flex;
            align-items: center;
        }
    }

    &__back-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
    }

    &__table-holder {
        overflow-x: auto;
        padding-top: 25px;
        width: 1200px;
    }

    &__summary-raqt {
        display: flex;
        justify-content: space-between;
        width: 770px;
        margin: 5px 0;

        &-left.item-wrapper {
            width: 250px;
            background-color: $raqtBlack200;
        }

        &-right {
            width: 500px;
        }
    }

    &__summary-title {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        color: $raqtPrimaryBlack;
        margin-top: 10px;
    }

    &__summary-user {
        display: flex;
        justify-content: space-between;
        width: 770px;
        margin: 5px 0;

        &-left.item-wrapper {
            width: 250px;
            background-color: $raqtBlack200;
        }

        &-wrapper {
            margin: 10px 0;
        }

        &-right {
            width: 150px;
            text-align: right;
            justify-content: flex-end;
        }

        &-with-additional {
            display: flex;
            align-items: center;
        }

        &-additional {
            margin-right: 25px;
        }
    }

    .raqt-table__row-0 .raqt-table__column-truly-value {
        .item-wrapper {
            background-color: $raqtBlack200;
            font-weight: 600;
        }
    }

    .raqt-table__body .raqt-table__column-falsy-value {
        .item-wrapper {
            background-color: transparent;
        }
    }

    .raqt-table__body {
        .item-wrapper {
            background: $greyBackground;
        }
    }
}