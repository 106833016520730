.tournament-card-head-section {
    display: flex;
    padding: 10px 7px 10px 10px;

    img {
        width: 50px;
        height: 50px;
    }

    &__name {
        padding-left: 12px;
    }

    &__logo-name {
        display: flex;
        align-items: center;
        width: 40%;
        padding: 0 10px 0 0px;
        font-size: 12px;
        line-height: 16px;
    }

    &__logo-holder {
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid #F2F2F2;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__info {
        display: flex;
        width: 60%;
        font-size: 10px;
        line-height: 14px;
    }

    &__information-text-box {
        min-height: 30px;
    }

    &__court {
        width: 50%;
        padding: 0 10px;

        &-title {
            text-transform: uppercase;
        }
    }

    &__match-id {
        width: 50%;
        padding: 0 18px 0 10px;

        &-title {
            text-transform: uppercase;
        }
    }
}