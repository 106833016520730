.tournament-scorecard-page-matches {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    max-width: 420px;
    width: 100%;
    min-height: 110px;

    &__match-holder {
        padding-top: 15px;

        &:first-child {
            padding-top: 0;
        }
    }
}