.tournament-play__dont-know {
    height: 100%;
    display: flex;
    align-items: center;

    &_placeholder {
        padding: 0 5px;
    }

    input {
        border: 1px solid #dee2e6;
        border-color: #dee2e6 !important;
        border-radius: 16px;
        min-height: 43px;
    }
}