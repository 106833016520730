.tournament-print-card-info-box {
    width: 100%;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FCFCFC;
        background: #909798;
        font-size: 10px;
        line-height: 14px;
    }
}