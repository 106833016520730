.public-tournament-info {
    max-width: 1290px;
    margin: auto;
    width: calc(100% - 20px);
    padding: 36px 0 100px;
    
    .item-wrapper {
        background-color: #fff;
        width: fit-content;
    }

    .d-flex.justify-content-between.align-items-center.py-2.px-2 {
        flex-direction: row-reverse;
        justify-content: start !important;
        padding-left: 0 !important;
    }

    &__tournament-name {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        letter-spacing: -0.02em;
        padding: 30px 0 90px;
    }

    &__tournament-card {
        display: flex;
        .card {
            overflow: hidden;
            width: max-content;
        }

        &-open {
            padding-right: 32px;
        }

        &-label {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
        }

        &-text {
            font-weight: 400;
            font-size: 24px;
            line-height: 140%;
            letter-spacing: -0.02em;
        }
        
        &-row {
            display: flex;
            padding-bottom: 20px;
        }

        &-location {
            padding-bottom: 20px;
        }

        &-icon {
            width: 80px;
            height: 80px;
            border-radius: 100%;
        }

        &-wrapper {
            width: 50%;
        }

        &-img-wrapper {
            max-width: 512px;
            width: 100%;
            height: 100%;
            
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-icons-wrapper {
            display: flex;
        }

        &-download {
            display: flex;
            align-items: center;
            padding-left: 70px;

            svg {
                margin: 0 10px;
            }
        }

        .left, .right {
            width: 50%;
        }
    }

    &__additional-info {
        font-weight: 800;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: -0.02em;
        padding: 60px 0 30px;
    }

    &__accordion {
        &-title {

        }

        &-content {
            padding: 10px 20px;
            * {
                font-size: 18px;
            }

            h2 {
                font-weight: 700;
            }
        }
    }
}

@media (max-width: 1050px) {
    .public-tournament-info {
        padding: 26px 0 50px;

        &__tournament-name {
            font-weight: 300;
            font-size: 32px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            padding: 0;
            padding-bottom: 50px;
        }

        &__additional-info {
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            padding: 32px 0 20px;
        }


        .public-tournament-info__tournament-card {
            display: flex;
            flex-direction: column;
        }

        &__tournament-card {
            &-img-wrapper {
                max-width: unset;

                width: 100%;
                height: 100%;
            }

            &-icons-wrapper_center {
                padding: 24px 0;
                justify-content: center;
            }

            &-wrapper {
                width: 100%;
            }

            &-row {
                flex-direction: column;
            }

            &-download {
                padding-left: unset;
            }

            .left, .right {
                width: 100%;
            }

            .card {
                width: 100%;
            }
        }
    }
}

