.tournament-scorecard-page-match-score {
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #5D6465;

    &__sort-key {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        background: #F2F2F2;
    }

    &__match-info {
        width: 100%;
    }
    
    &__team {
        display: flex;
        width: 100%;
        border-top: 1px solid #F2F2F2;

        &:last-child {
            border-bottom: 1px solid #F2F2F2;
        }

        div {
            min-height: 100%;
        }

        &-info {
            display: flex;
            width: 100%;
        }

        &-seed {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #909798;
            font-size: 10px;
        }

        &-score {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            background: #F2F2F2;

            &_winner {
                color: #FAFAFA;
                background: #5D6465;
            }
        }
    }
}