.sell-page {
    &__field {
        width: 360px;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        padding: 0 13px;
    }

    &__t-shirt {
        width: 360px;

        &-sub {
            width: 100%;
        }
    }

    &__note-danger {
        color: #FF0F65;
    }

    &__raqt-apps {
        display: flex;
        flex-wrap: wrap;
        width: 400px;
        max-width: 100%;
        justify-content: space-between;
    }

    &__tournament-info {
        background-color: #FFF8F3;
        width: 100%;
        padding: 100px 0;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap-reverse;
        align-items: center;

        &-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-text {
            width: 330px;
            h1 {
                font-weight: 600;
            }
        }

        &-image {
            width: 320px;

            img {
                width: 100%;
                padding: 10px 15px;
            }
        }
    }

    &__sell-info {
        background-color: #fff;
        padding: 100px 30px;

        &-title {
            text-align: center;
        }

        &-paragraph {
            padding: 15px 0;

            &_apple {
                padding-left: 30px;
            }
        }

        &-block {
            padding: 10px 0;

            p {
                margin: 0;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }

    @media (max-width: 650px) {
        .sell-page__sell-info {
            padding: 100px 15px;
        }
    }

    &__form-total {
        display: flex;
        justify-content: space-between;
        width: 360px;
        padding-left: 30px;
        font-weight: 600;
    }

    &__form-submit {
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        width: 300px;
        margin: auto;
        flex-wrap: wrap;
    }

    form {
        background-color: #FFFBFE;
        padding-bottom: 50px;
        padding-top: 100px;
    }

    
    @media (max-width: 650px) {
        .sell-page__tournament-info {
            padding: 50px 0;
        }
    }

    &__policy-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;

        a {
            text-decoration: none;
        }
    }


    @media (max-width: 560px) {
        .sell-page__raqt-apps {
            justify-content: center;

            &-single {
                padding: 10px 0;
            }
        }
        
        .sell-page__form-total {
            padding-left: 17px;
        }
    }

    &__receipt-row {
        display: flex;
        flex-wrap: wrap;

        span {
            padding-right: 60px;
        }
    }

}