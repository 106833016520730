.form-control{
  min-height: 50px;
  border-radius: 16px;
}

.form-select{
  min-height: 50px;
  border-radius: 16px;
}

.descriptionTextArea{
  resize: none;
  height: 116px;
}

.form-control[readonly] {
  background-color: #FFFFFF;
}

.raqt__select {
  position: relative;

  &-disabled-block {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #ffffffa3;
  }
}