@import '../../variables';

.raqt-switcher {
    display: flex;
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
    mask-image: linear-gradient(90deg, transparent, transparent 20px , #000 30px, #000 calc(100% - 30px),transparent calc(100% - 20px),transparent);
    white-space: nowrap;
    padding-bottom: 15px;

    &__item {
        overflow: visible;
        margin: 0 10px;
        width: fit-content;
        padding: 14px 30px;
        border-radius: 30px;
        background-color: #F2F2F2;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: unset;
        cursor: pointer;
        white-space: nowrap;

        &.active {
            background-color: $whiteBackground;
        }
    }
} 