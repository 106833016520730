@import '../../variables';

.toggle-container {
    width: 62px;
    background-color: #0474EF;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    padding: 2px;
    height: 32px;
    position: relative;
    border-radius: 20px;
  }
  
  .dialog-button {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #FFFFFF;
    color: white;
    padding: 8px 12px;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: unset;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    position: absolute;
    left: 32px;
    transition: all 0.3s ease;
    height: 28px;
    border-radius: 50%;
  }
  
  .off {
    left: 2px;
  }

  .off-container {
    background-color: $raqtBlack200;
    transition: all 0.3s ease;
  }
  
  .toggle-wrapper {
    position: relative;

    &__disabled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: $raqtBlack200Opacity;
        border-radius: 20px;
    }
  }