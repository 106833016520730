@import '../../variables';


.raqt-popup {
    &_light-content {
        background: $whiteBackground;
        border-radius: 5px;
        padding: 0 5px;
        border: 1px solid $whiteBackground;
    }

    &_dark-content {
        color: $textWhite;
        background: $darkBackground;
        border-radius: 5px;
        padding: 0 5px;
        border: 1px solid $darkBackground;

        div:first-child {
            color: $darkBackground !important;
        }
    }
}
