@import '../../variables.scss';


.raqtAdminDashboard {

/* DESKTOP */


    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        text-align: center;
        margin-top: 65px;
        margin-bottom: 50px;
    }


}

/* MOBILE */

@media (max-width: 800px) {
    .raqtAdminDashboard {

        &__title {
            font-weight: 300;
            font-size: 64px;
            line-height: 140%;
            text-align: center;
            margin-top: 65px;
            margin-bottom: 50px;
        }
    }


}