.tournament-scorecard-page-section {

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
    }
    div {
        scrollbar-width: none;
    }

    &__title {
        font-size: 24px;
        line-height: 140%;
        font-weight: 700;
    }

    &__children {
        padding: 20px 5px;
        overflow-x: auto;
        mask-image: linear-gradient(90deg, transparent, #000 7%, #000 93%, transparent);
    }
}

@media (min-width: 800px) {
    .tournament-scorecard-page-section__children {
        mask-image: linear-gradient(90deg, transparent, #000 2%, #000 98%, transparent);
    }
}