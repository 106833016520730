.tournament-match__match-id {
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    border-radius: 16px;
    padding: 0px 15px;

    &-title {
        padding: 0px 12px;
    }
}

.match__accordion-status {
    position: absolute;
    top: 23%;
    right: 70px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.match-section__match-input {
    width: 100%;
}

.match-section__score-button-padding {
    padding-left: 45px;
}