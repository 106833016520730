$active:#007AFF;
$notActive:#5D6465;

$textGray: #C5C9C9;
$textWhite: #FCFCFC;
$darkBackground: #151717;
$whiteBackground:  #FFFFFF;
$greyBackground: #F2F2F2;
$raqtBlack200: #E5E5E5;
$raqtBlack200Opacity: #e5e5e583;


$raqtPrimaryBlack: #2C2F30;