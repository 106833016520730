@import '../../variables';

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    border: 1px solid #000;

    &__wrapper {
        position: relative;
    }

    &__disabled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: $raqtBlack200Opacity;
        border-radius: 20px;
    }
  }