.raqt-sign-up {
    max-width: 750px;
    margin: auto;
    letter-spacing: -0.02em;
    margin-bottom: 60px;

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        text-align: center;
        margin-top: 65px;
        margin-bottom: 50px;
    }

    &__subtitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 20px;
    }
    
    &__requirements {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 60px;
    }
}

@media (max-width: 800px) {
    .raqt-sign-up {
        padding: 0 15px;

        &__title {
            font-size: 32px;
        }
        &__subtitle {
            font-size: 16px;
        }
        &__requirements {
            font-size: 18px;
        }
    }
}