.tournament-scorecard-page-match-modal-team {
    display: flex;
    justify-content: space-between;

    &__avatars {
        display: flex;

        &_2 {
            margin-left: -10px;
        }
    }

    &__team-holder-home {
        padding-bottom: 20px;
    }

    &__team-info-box {
        padding-top: 10px;
    }

    &__team-number {
        font-weight: 300;
        font-size: 10px;
        line-height: 140%;
    }

    &__team-player-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
    }

    &__score {
        position: relative;
        min-width: 35px;
        background: #F2F2F2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1px;

        &-holder {
            display: flex;
        }

        &-game {
            position: absolute;
            top: 5px;
            width: 100%;
            text-align: center;
            font-weight: 300;
            font-size: 14px;
            line-height: 140%;
        }

        &-number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
        }
    }
}