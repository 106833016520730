.tournament-card-team-section {
    display: flex;
    font-size: 14px;
    line-height: 20px;

    &__title {
        text-align: left;
    }

    &__event {
        padding-right: 10px;
    }

    &__side {
        width: 50%;

        &_left {
            text-align: start;
            padding: 0 10px;
        }

        &_right {
            .tournament-card-player-holder {
                margin-left: 5px;
            }

            .tournament-card-player-holder__player-info {

                justify-content: end;
            }
        }
    }

    &__format-holder {
        display: flex;
        justify-content: space-between;
        padding-right: 4px;
        
        div {
            width: unset;
        }
    }
}