.raqt-reset {
    max-width: 750px;
    margin: auto;
    letter-spacing: -0.02em;
    margin-bottom: 60px;

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 140%;
        text-align: center;
        margin-top: 65px;
        margin-bottom: 50px;
    }
}


@media (max-width: 800px) {
    .raqt-reset {
        padding: 0 15px;
        
        &__title {
            font-size: 32px;
        }
    }
}