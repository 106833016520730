@import '../../../../variables';

.tournament-scorecard-page-group-info {
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid $raqtBlack200;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
    padding-bottom: 6px;
    max-width: 670px;
    align-self: center;
    width: 100%;

    &__head {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        padding: 12px 15px;
    }

    &__body {
        font-weight: 300;
        font-size: 14px;
        line-height: 140%;
        padding: 0 15px;

        &-row {
            display: flex;
            justify-content: space-between;
            padding: 6px 0;
        }
    }

    &__number {
        width: 35px;
    }

    &__team {
        width: 120px;
    }

    &__matches {
        width: 30px;
        text-align: center;
    }

    &__difference {
        width: 40px;
        text-align: center;
    }

    &__gw {
        width: 30px; 
        text-align: center;       
    }

    &__wins {
        width: 30px;
        text-align: center;
    }
}