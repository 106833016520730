.tournament-dashboard-tag {
    min-height: 600px;


}

@media (max-width: 800px) {
    .tournament-dashboard-tag {

    }
}