.raqt-table {

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    overflow: auto;

    &__blank-head {
        .item-wrapper {
            background: transparent;
        }
    }

    &__wrapper {
        width: 100%;
    } 

    &__column {
        &_center {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .item-wrapper {
                justify-content: center;
            }
        }
    
        &_left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;

            .item-wrapper {
                justify-content: flex-start;
            }
        }
    
        &_right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: right;

            .item-wrapper {
                justify-content: flex-end;
            }
        }

        &_small {
            flex: 1 1 10%;
            padding: 0 4px;
        }
    
        &_standard {
            flex: 1 1 20%;
            padding: 0 4px;
        }

        &_big {
            flex: 1 1 30%;
            padding: 0 4px;
        }
    }

    &__body {
        .item-wrapper {
            background: #fff;
        }
    }

    &__row-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
    }
}