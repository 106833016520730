.tournament-print-card {
    padding: 10px;
    background: #fff;
    zoom: 1.64;

    &__home-team {
        padding-bottom: 10px;
    }

    &__away-team {
        transform: rotate(180deg);
    }  
}